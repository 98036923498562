import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBanners3,
  getProducts,
  getBanners2,
  getBanners1,
} from "../../redux/apiCalls";
import { RootState } from "../../redux/store";
// Components
import Header from "../components/Header";
// import Categories from "../components/Categories";
import Banner from "../components/Banner";
import ProductContainer from "../components/ProductsContainer";
import Partners from "../components/Partners";
import Contact from "../components/Contact";
import Location from "../components/Location";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import FilteredProductsComponent from "../components/FilteredProducts";

export default function Home() {
  const { banners: banners1 } = useSelector(
    (state: RootState) => state.banner1
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getProducts(dispatch);
    getBanners1(dispatch);
    getBanners3(dispatch);
    getBanners2(dispatch);

    return () => {};
  }, [dispatch]);

  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      {banners1 && banners1.length > 0 && <Banner images={banners1} />}
      <ProductContainer startAt={0} productsNumber={8} title="Top Products" />
      <FilteredProductsComponent fixedCategory="Zbritje" filter={false} />
      <Partners />
      <Contact />
      <Location />
      <Footer />
    </>
  );
}
