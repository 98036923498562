export default function Location() {
  return (
    <section className="container location">
      <iframe
        className="locationScreen"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11735.241522674!2d21.1664994!3d42.6653735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f2c8f74fbcb%3A0x8497a8c6d7069055!2sBetaTech%20SH.P.K!5e0!3m2!1sen!2s!4v1715278320705!5m2!1sen!2s"
        frameBorder={0}
        title="Location"
      ></iframe>
    </section>
  );
}
