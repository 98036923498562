import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SkeletonPost from "../../components/Skeletons/SkeletonPost";

import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import SectionTitle from "./SectionTitle";

export default function ProductContainer({
  productsNumber,
  title,
  more = true,
  startAt,
  filter,
}: any) {
  const { filteredProductsByCategory, categoryLoading } = useSelector(
    (state: RootState) => state.product
  );

  // useEffect(() => {
  //     getProducts(dispatch);
  // }, [sortOrder])

  // if (loadingPage || isLoading || error) return <Loader />

  if (categoryLoading) {
    return (
      <div className="container">
        <div className="row">
          {filteredProductsByCategory?.length > 0
            ? filteredProductsByCategory?.map((_: any, i: any) => {
                return (
                  <div className="col-lg-3" key={i}>
                    <SkeletonPost />
                  </div>
                );
              })
            : Array.from({ length: 8 }).map((_, i) => (
                <div className="col-lg-3" key={i}>
                  <SkeletonPost />
                </div>
              ))}
        </div>
      </div>
    );
  }

  const productsToDisplay = filteredProductsByCategory?.slice(
    startAt,
    productsNumber
  );

  return (
    <div className="productsContainer flex container">
      <SectionTitle filter={filter}>{title}</SectionTitle>
      <div className="cardsContainer flex">
        {productsToDisplay?.length > 0 ? (
          productsToDisplay?.map((product: any) => (
            <ProductCard data={product} loading={categoryLoading} />
          ))
        ) : (
          <div className="empty_wishlist">
            <h5>Asnjë produkt me kategorinë e perzgjedhur</h5>
          </div>
        )}
      </div>
      {more && (
        <Link
          to="/shop"
          className="blueButton medium fs500 ffPoppins clrWhite transition"
        >
          Shiko më shumë
        </Link>
      )}
    </div>
  );
}
