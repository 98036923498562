// import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Location from "../components/Location";
import Navigation from "../components/Navigation";
import OrderCompletedComponent from "../components/OrderCompleted";

export default function OrderCompleted() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <OrderCompletedComponent />
      <Location />
      <Footer />
    </>
  );
}
