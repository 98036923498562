import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { apiUrl } from "../../constants/backendUrl";
import { formatDate } from "../../helpers/formatDate";
import axios from "axios";
import { TableLoader } from "../../helpers/TableLoader";

export default function UserOrder() {
  const user = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/orders/my-orders/${user._id}`, {
        headers: { Authorization: "Bearer " + user?.token },
      })
      .then((res) => {
        if (res.status === 201) {
          setOrders(res.data.orders);
        }
        setLoading(false);
      });
  }, [user]);

  if (loading) return <TableLoader items={orders} />;

  return (
    <div className="userOrder flex container">
      {orders?.length > 0 ? (
        <table className="userOrderTable">
          <tr>
            <th className="fs700 bold ffPoppins clrPrimary">Përdoruesi</th>
            <th className="fs700 bold ffPoppins clrPrimary">Adresa</th>
            <th className="fs700 bold ffPoppins clrPrimary">Produkti</th>
            <th className="fs700 bold ffPoppins clrPrimary">Totali</th>
            <th className="fs700 bold ffPoppins clrPrimary">Data</th>
          </tr>
          {orders &&
            orders?.map((order: any) => (
              <tr key={order?._id}>
                <td
                  className="fs500 medium ffPoppins clrDarkGray"
                  data-title="user-name"
                >
                  {order.firstName} {order.lastName}
                </td>
                <td
                  className="fs500 medium ffPoppins clrDarkGray"
                  data-title="user-address"
                >
                  {order.address}
                </td>
                <td className="fs500 medium ffPoppins clrDarkGray">
                  {order?.orderItems?.map(
                    (item: any, index: any) => item?.product.title
                  )}
                </td>

                <td
                  className="fs500 medium ffPoppins clrDarkGray"
                  data-title="Total"
                >
                  {order?.orderItems
                    .reduce(
                      (a: any, product: any) =>
                        a +
                        (user?.role === "shumice"
                          ? product?.product?.priceShumice
                          : product?.product?.pricePakice) *
                          product.quantity,
                      0
                    )
                    .toFixed(2)}
                  €
                </td>
                <td
                  className="fs500 medium ffPoppins clrDarkGray"
                  data-title="Date of order"
                >
                  {formatDate(order?.createdAt)}
                </td>
              </tr>
            ))}
        </table>
      ) : (
        <div className="noOrders">
          <h3 className="fs800 medium ffPoppins clrBlack">
            You don't have any orders yet.
          </h3>
        </div>
      )}
    </div>
  );
}
