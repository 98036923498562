import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

import CartTable from "./cart/CartTable";
import CartTotals from "./cart/CartTotals";

export default function Cart() {
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <section className="cart container flex">
      <div className="cartMain flex">
        <CartTable user={user} />
        <CartTotals user={user} />
      </div>
      <Link
        className="buttonClear clrSecondary fs700 semiBold uppercase"
        to="/shop"
      >
        Return to shop
      </Link>
    </section>
  );
}
