import { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import { apiUrl } from "../../constants/backendUrl";

export default function ProductImages({ product }: any) {
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    setActiveImage(product?.images[0]);
  }, [product]);

  return (
    <div className="productImages">
      {product?.images && (
        <div className="imageContainer flex">
          <img
            className="productImagePreview"
            src={`${apiUrl}/images/${activeImage}`}
            alt="Product"
          />
        </div>
      )}
      {product?.images?.length > 1 && (
        <div>
          <Slide
            slidesToShow={3}
            autoplay={false}
            responsive={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {product?.images &&
              product?.images?.map((item: any, index: any) => (
                <div key={index} className="product_gallery_item">
                  <a href="javascript:void();">
                    <img
                      className="imgSmall"
                      src={`${apiUrl}/images/${item}`}
                      onClick={() => setActiveImage(item)}
                      alt="product_small_img"
                    />
                  </a>
                </div>
              ))}
          </Slide>
        </div>
      )}
    </div>
  );
}
