import Category from "./Category";

import Laptop from "../../assets/images/subcategories/laptop.jpeg";
import BackPack from "../../assets/images/subcategories/backPack.jpeg";
import Accessories from "../../assets/images/subcategories/accessories.jpeg";

export default function LaptopNav() {
  return (
    <div className="navSubCategories navSubCategoriesLaptop flex">
      <Category img={Laptop} to="/Laptop">
        Laptop
      </Category>
      <Category img={BackPack} to="/Çanta">
        Çanta
      </Category>
      <Category img={Accessories} to="/Aksesorë">
        Aksesorë
      </Category>
    </div>
  );
}
