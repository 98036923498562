import { useState } from "react";
import { ContactData } from "../../../types/types";
import { handleOnChange } from "../../../helpers/handleChange";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "../../../constants/backendUrl";

export default function ContactForm() {
  const [formData, setFormData] = useState<ContactData>({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({} as ContactData);

  const validateForm = (data: ContactData) => {
    const errors = {} as ContactData;

    if (!data?.name) {
      errors.name = "Name is required";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data?.phoneNumber) {
      errors.phoneNumber = "Price is required";
    } else if (isNaN(Number(data?.phoneNumber))) {
      errors.phoneNumber = "Price must be a number";
    }

    if (!data?.subject) {
      errors.subject = "Subject is required";
    }

    if (!data?.message) {
      errors.message = "Description is required";
    }

    return errors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateForm(formData);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const res = await axios.post(`${apiUrl}/contacts`, formData);

      if (res.status === 200) {
        // reset form data.
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
        toast.success("Faleminderit për mesazhin.");
      }
    }
  };

  return (
    <form className="contactForm flex" onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        id="name"
        className="userInput clrDarkGray fs400 ffPoppins"
        placeholder="Emri *"
        required
        value={formData?.name}
        onChange={(e) =>
          handleOnChange(e, formData, setFormData, errors, setErrors)
        }
      />
      {errors?.name && (
        <div className="heading_error">
          <p className="text-left mb-0">{errors?.name}</p>
        </div>
      )}
      <input
        type="email"
        name="email"
        id="email"
        className="userInput clrDarkGray fs400 ffPoppins"
        placeholder="Email *"
        required
        value={formData?.email}
        onChange={(e) =>
          handleOnChange(e, formData, setFormData, errors, setErrors)
        }
      />
      {errors?.email && (
        <div className="heading_error">
          <p className="text-left mb-0">{errors?.email}</p>
        </div>
      )}
      <input
        type="number"
        name="phoneNumber"
        id="phoneNumber"
        className="userInput clrDarkGray fs400 ffPoppins"
        placeholder="Numri i telefonit *"
        required
        value={formData?.phoneNumber}
        onChange={(e) =>
          handleOnChange(e, formData, setFormData, errors, setErrors)
        }
      />
      {errors?.phoneNumber && (
        <div className="heading_error">
          <p className="text-left mb-0">{errors?.phoneNumber}</p>
        </div>
      )}
      <input
        type="text"
        name="subject"
        id="subject"
        className="userInput clrDarkGray fs400 ffPoppins"
        placeholder="Subjekti *"
        required
        value={formData?.subject}
        onChange={(e) =>
          handleOnChange(e, formData, setFormData, errors, setErrors)
        }
      />
      {errors?.subject && (
        <div className="heading_error">
          <p className="text-left mb-0">{errors?.subject}</p>
        </div>
      )}
      <textarea
        name="message"
        id="message"
        className="userMessage"
        placeholder="Mesazhi *"
        required
        value={formData?.message}
        onChange={(e) =>
          handleOnChange(e, formData, setFormData, errors, setErrors)
        }
      ></textarea>
      {errors?.message && (
        <div className="heading_error">
          <p className="text-left mb-0">{errors?.message}</p>
        </div>
      )}
      <input
        type="submit"
        className="submitButton clrWhite medium fs300 uppercase transition"
        value="Dërgoni"
      />
    </form>
  );
}
