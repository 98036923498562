import ContactIntro from "./contact/ContactIntro";
import ContactForm from "./contact/ContactForm";
import ContactInfo from "./contact/ContactInfo";

export default function Contact() {
  return (
    <section className="contactContainer container flex">
      <ContactIntro />
      <div className="contact flex">
        <ContactForm />
        <ContactInfo />
      </div>
    </section>
  );
}
