import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants/backendUrl";
import { ProductSkeleton } from "../../components/ProductDetail/ProductSkeleton";
import RelatedProducts from "../../components/RelatedProducts/RelatedProducts";
import ProductAbout from "./ProductAbout";
import ProductImages from "./ProductImages";

export default function Product() {
  const [product, setProduct] = useState({} as any);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedProductsLoading, setRelatedProductsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/products/${id}`)
        .then((res) => {
          setProduct(res?.data?.product);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setProduct({});
    }
  }, [id]);

  useEffect(() => {
    if (product) {
      setRelatedProductsLoading(true);
      axios
        .get(`${apiUrl}/products/by-category/${product?.category}`)
        .then((res) => {
          setRelatedProducts(res?.data?.products);
          setRelatedProductsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [product]);

  return (
    <section className="productSingle container">
      <div className="productContainer flex">
        {Object.keys(product).length > 0 ? (
          <>
            <ProductImages product={product} />
            <ProductAbout product={product} />
          </>
        ) : (
          <ProductSkeleton />
        )}
      </div>
      <RelatedProducts
        relatedProducts={relatedProducts}
        product={product}
        loading={relatedProductsLoading}
      />
    </section>
  );
}
