import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export default function CartTotals({ user }: any) {
  const cartProducts = useSelector((state: RootState) => state.cart.products);
  let totalPrice = cartProducts?.reduce((a, product) => {
    if (product?.sasia === 0) {
      return a;
    } else {
      return (
        a +
        (user?.role === "shumice"
          ? product?.product?.priceShumice
          : product?.product?.pricePakice) *
          product.quantity
      );
    }
  }, 0);

  return (
    <div className="checkout">
      <h2 className="fs700 bold clrBlack uppercase">Card Totals</h2>
      <hr />
      <div className="checkoutInfo flex">
        <h3 className="fs500 medium clrBlack uppercase">Sub-Total</h3>
        <h3 className="fs500 medium clrDarkGray">
          {cartProducts
            .reduce((a, product) => {
              if (product?.sasia && product?.sasia > 0) {
                return (
                  a +
                  (user?.role === "shumice"
                    ? product?.product?.priceShumice
                    : product?.product?.pricePakice) *
                    product.quantity
                );
              }
              return a;
            }, 0)
            .toFixed(2)}
          €
        </h3>
      </div>
      <div className="checkoutInfo flex">
        <h3 className="fs500 medium clrBlack uppercase">Shipping</h3>
        <h3 className="fs500 medium clrDarkGray">Free</h3>
      </div>
      <hr />
      <div className="checkoutInfo flex">
        <h2 className="clrBlack fs600 bold uppercase">Total:</h2>
        <h2 className="fs600 medium uppercase clrDarkGray">
          {cartProducts
            .reduce(
              (a, product) =>
                a +
                (product?.sasia && product?.sasia > 0
                  ? (user?.role === "shumice"
                      ? product?.product?.priceShumice
                      : product?.product?.pricePakice) * product.quantity
                  : 0),
              0
            )
            .toFixed(2)}
          €
        </h2>
      </div>
      <button className="blueBtn fs700 transition pointer uppercase semiBold clrWhite">
        <Link
          className="blueBtnText clrWhite"
          to={
            cartProducts?.length > 0 && totalPrice !== 0 ? "/checkout" : "/cart"
          }
        >
          Proceed to checkout
        </Link>
      </button>
    </div>
  );
}
