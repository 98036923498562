import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiUrl } from "../../../constants/backendUrl";
import {
  removeFromCart,
  decreaseQuantity,
  increaseQuantity,
} from "../../../redux/shoppingCartRedux";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { fetchShoppingCartQuantity } from "../../../redux/apiCalls";

import RemoveIcon from "../../assets/icons/delete.svg";

export default function CartTable({ user }: any) {
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.cart.products);

  useEffect(() => {
    fetchShoppingCartQuantity(dispatch);
  }, [dispatch]);

  return (
    <div className="cartTableContainer">
      <table className="cartTable">
        <tr>
          <th className="fs600 medium ffPoppins clrPrimary uppercase">
            Products
          </th>
          <th className="fs600 medium ffPoppins clrPrimary uppercase">Price</th>
          <th className="fs600 medium ffPoppins clrPrimary uppercase">
            Quantity
          </th>
          <th className="fs600 medium ffPoppins clrPrimary uppercase">
            Sub-total
          </th>
        </tr>

        <tr></tr>
        {products?.map((product) => (
          <tr key={product?.product?._id}>
            <td className="flex">
              <img
                onClick={() => dispatch(removeFromCart(product.product))}
                src={RemoveIcon}
                alt="Remove"
                width={20}
                height={20}
                className="pointer "
              />
              <Link to={`/product/${product.product._id}`}>
                <img
                  className="productImage"
                  src={`${apiUrl}/images/${product?.product?.images[0]}`}
                  alt="Product"
                  width={75}
                  height={75}
                />
              </Link>
              <Link to={`/product/${product.product._id}`}>
                <h2 className="fs500 medium ffPoppins clrBlack">
                  {product?.product?.title.substring(0, 20)}...
                </h2>
              </Link>
            </td>
            {/* <td className="product-price" data-title="Price">${product?.product?.discountPrice ? product?.product?.discountPrice : product.product?.price}€</td> */}
            <td className="fs500 medium ffPoppins clrBlack" data-title="Price">
              {user?.role === "shumice"
                ? product?.product?.priceShumice?.toFixed(2)
                : product?.product?.pricePakice?.toFixed(2)}
              €
            </td>
            <td className="quantityButtons flex">
              {product?.sasia && product?.sasia ? (
                <>
                  <button
                    onClick={() =>
                      dispatch(
                        decreaseQuantity({
                          product: product.product,
                          quantity: product.quantity,
                        })
                      )
                    }
                  >
                    -
                  </button>
                  <button>{product.quantity}</button>
                  <button
                    onClick={() =>
                      dispatch(
                        increaseQuantity({
                          product: product.product,
                          quantity: product.quantity,
                        })
                      )
                    }
                  >
                    +
                  </button>
                </>
              ) : (
                <span className="badge bg-red"> Out of Stock</span>
              )}
            </td>
            {/* <td className="product-subtotal" data-title="Total">${(product.product.discountPrice ? product.product.discountPrice : product.product.price) * (product.quantity)}</td> */}
            <td className="fs500 medium ffPoppins clrBlack">
              {" "}
              {(
                (user?.role === "shumice"
                  ? product?.product?.priceShumice
                  : product?.product?.pricePakice) * product.quantity
              )?.toFixed(2)}
              €
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}
