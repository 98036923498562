import { useDispatch } from "react-redux";
import { setSortOrder } from "../../redux/productRedux";

export default function SectionTitle({ children, filter }: any) {
  const disptach = useDispatch();

  const handleSortOrderChange = (e: any) => {
    disptach(setSortOrder(e.target.value));
  };

  return (
    <div className="titleContainer flex">
      <h2 className="ffPoppins fs800 semiBold clrBlack">{children}</h2>
      {filter && (
        <select
          name=""
          id="sortFilter"
          className="form-control form-control-sm"
          onChange={handleSortOrderChange}
        >
          <option value="">Default Sorting</option>
          <option value="lowToHigh">Sort by price: low to high</option>
          <option value="highToLow">Sort by price: high to low</option>
        </select>
      )}
    </div>
  );
}
