import Header from "../components/Header";
import AccountComponent from "../components/Account";
import Location from "../components/Location";
import Footer from "../components/Footer";
// import Categories from "../components/Categories";
import Navigation from "../components/Navigation";

export default function Account() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <AccountComponent />
      <Location />
      <Footer />
    </>
  );
}
