import Logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";

export default function FooterTop() {
  return (
    <section className="footerTopWrapper">
      <div className="footerTop flex container">
        <Link to="/">
          <img className="footerLogo" src={Logo} alt="BetaTech Logo" />
        </Link>
        <ul className="footerListContainer flex">
          <div className="footerList flex">
            <h3 className="fs700 semiBold clrBlack">Kategoritë</h3>
            <div className="footerListCategories flex">
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/pc gaming">
                  Gaming PC
                </Link>
              </li>
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/laptop">
                  Laptop
                </Link>
              </li>
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/tastierë">
                  Tastierë
                </Link>
              </li>
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/maus">
                  Maus
                </Link>
              </li>
            </div>
          </div>
          <div className="footerList flex">
            <h3 className="fs700 semiBold clrBlack">Llogaria</h3>
            <div className="footerListCategories flex">
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/account">
                  Account
                </Link>
              </li>
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/wishlist">
                  Wishlist
                </Link>
              </li>
              <li>
                <Link className="footerItem clrDarkGray fs600" to="/cart">
                  Cart
                </Link>
              </li>
            </div>
          </div>
          <div className="footerList flex">
            <h3 className="fs700 semiBold clrBlack">Kontakti</h3>
            <div className="footerListCategories flex">
              <li className="clrDarkGray fs600">info@betatech-ks.com</li>
              <li className="clrDarkGray fs600">46 Zejnel Salihu</li>
              <li className="clrDarkGray fs600">Prishtina 10000</li>
              <li className="clrDarkGray fs600">048 220 989</li>
            </div>
          </div>
        </ul>
      </div>
    </section>
  );
}
