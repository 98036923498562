import { useState } from "react";
import { toggleWishlist } from "../../redux/wishlistRedux";
import { addToCart } from "../../redux/shoppingCartRedux";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import ProductAdded from "../../helpers/ProductAdded";
import {
  increaseQuantity,
  decreaseQuantity,
} from "../../helpers/quantityHelpers";
import { toast } from "react-toastify";
import { Product } from "../../types/types";

import CashIcon from "../assets/icons/cash.svg";
import WarrantyIcon from "../assets/icons/warranty.svg";
import ShieldIcon from "../assets/icons/shield.svg";
import TruckIcon from "../assets/icons/truck.svg";

export default function ProductAbout({ product }: any) {
  const [productQuantity, setProductQuantity] = useState(1);
  const dispatch = useDispatch();
  const showModal = useSelector((state: RootState) => state.wishlist.added);
  const cartProducts = useSelector((state: RootState) => state.cart.products);
  const user = useSelector((state: RootState) => state.user.user);

  const handleWishlistClick = () => {
    dispatch(toggleWishlist(product));
  };

  const handleAddToCart = (product: Product, quantity: number) => {
    const existingItem = cartProducts.find(
      (item) => item?.product?._id === product._id
    );

    if (existingItem) {
      return toast.error("Produkti ekziston në shportë!");
    }

    if (product?.sasia < quantity) {
      return toast.error("Nuk ka sasi te mjaftueshme në stock!");
    }

    dispatch(addToCart({ product, quantity: quantity }));
    toast.success("Produkti u shtua në shportë.");
  };

  const calculateDiscountedPrice = () => {
    if (product?.zbritja) {
      if (user?.role === "shumice") {
        return (product?.priceShumice - product?.zbritja).toFixed(2);
      } else {
        return (product?.pricePakice - product?.zbritja).toFixed(2);
      }
    }
    return null;
  };

  const discountedPrice = calculateDiscountedPrice();

  return (
    <div className="productAbout">
      <ProductAdded show={showModal} />
      <h1 className="clrPrimary fs800 semiBold ffPoppins">{product?.title}</h1>
      <h3 className="clrPrimary fs400 medium ffPoppins">
        {discountedPrice &&
        discountedPrice <
          (user?.role === "shumice"
            ? product?.priceShumice
            : product?.pricePakice) ? (
          <div>
            <span className="clrPrimary fs400 medium ffPoppins">
              {discountedPrice}€
            </span>
            <del className="priceBefore clrPrimaryOpacity">
              {user?.role === "shumice"
                ? product?.priceShumice?.toFixed(2)
                : product?.pricePakice?.toFixed(2)}
              €
            </del>
          </div>
        ) : (
          <span className="clrPrimary fs400 medium ffPoppins">
            {user?.role === "shumice"
              ? product?.priceShumice?.toFixed(2)
              : product?.pricePakice?.toFixed(2)}
            €
          </span>
        )}
      </h3>
      <hr />
      <p className="fs400 clrDarkGray">{product?.description}</p>
      <hr />
      <div className="productButtons flex">
        <div className="count flex">
          <button
            className="fs700"
            onClick={() =>
              decreaseQuantity(setProductQuantity, productQuantity)
            }
          >
            -
          </button>
          <button className="fs700">{productQuantity}</button>
          <button
            className="fs700"
            onClick={() =>
              increaseQuantity(setProductQuantity, productQuantity)
            }
          >
            +
          </button>
        </div>
        <button
          className="blueButton fs600 medium clrWhite transition"
          onClick={() => handleAddToCart(product, productQuantity)}
        >
          Add to Cart
        </button>
      </div>
      <hr />
      <div className="flex" style={{ justifyContent: "space-between" }}>
        <button
          className="addToWishlist transition pointer fs600 clrPrimary medium"
          onClick={handleWishlistClick}
        >
          Add to wishlist
        </button>
        <div className="flex" style={{ gap: 5 }}>
          <img src={CashIcon} alt="Cash" width={27} />
          <img src={ShieldIcon} alt="Shield" width={27} />
          <img src={TruckIcon} alt="Delivery" width={27} />
          <img src={WarrantyIcon} alt="Warranty" width={27} />
        </div>
      </div>
    </div>
  );
}
