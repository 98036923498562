import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import WishlistComponent from "../components/Wishlist";

export default function Wishlist() {
  return (
    <>
      <Header />
      <Navigation />
      <WishlistComponent />
      <Footer />
    </>
  );
}
