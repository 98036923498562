export default function ContactInfo() {
  return (
    <div className="contactInfo flex">
      <div>
        <h3 className="clrBlack fs600 medium ffPoppins">Numri i telefonit</h3>
        <p className="clrDarkGray fs400 ffPoppins">048 220 989</p>
      </div>
      <div>
        <h3 className="clrBlack fs600 medium ffPoppins">Email</h3>
        <p className="clrDarkGray fs400 ffPoppins">info@betatech-ks.com</p>
      </div>
      <div>
        <h3 className="clrBlack fs600 medium ffPoppins">Lokacioni</h3>
        <p className="clrDarkGray fs400 ffPoppins">
          Zejnel Salihu, nr. 46, Pristina, Kosovo
        </p>
      </div>
    </div>
  );
}
