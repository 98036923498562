import Category from "./Category";

import PC from "../../assets/images/subcategories/pc.png";
import Monitor from "../../assets/images/subcategories/monitor.jpeg";
import Mouse from "../../assets/images/subcategories/mouse.webp";
import Keyboard from "../../assets/images/subcategories/keyboard.jpeg";
import Headphones from "../../assets/images/subcategories/headphones.jpeg";
import Console from "../../assets/images/subcategories/console.webp";
import Chair from "../../assets/images/subcategories/chair.jpeg";

export default function Gaming() {
  return (
    <div className="navSubCategories navSubCategoriesGaming flex">
      <Category img={PC} to="/BetaTech Builds">
        BetaTech Builds
      </Category>
      <Category img={Monitor} to="/Monitor Gaming">
        Monitor Gaming
      </Category>
      <Category img={Mouse} to="/Maus">
        Maus
      </Category>
      <Category img={Keyboard} to="/Tastierë">
        Tastierë
      </Category>
      <Category img={Headphones} to="/Kufje">
        Kufje
      </Category>
      <Category img={Console} to="/Console">
        Console
      </Category>
      <Category img={Chair} to="/Karrigë & Tavolinë">
        Karrigë & Tavolinë
      </Category>
    </div>
  );
}
