import { useEffect } from "react";
import { toggleWishlist } from "../../redux/wishlistRedux";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../constants/backendUrl";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { addToCart } from "../../redux/shoppingCartRedux";
import { toast } from "react-toastify";
import { Product } from "../../types/types";
import { fetchWishlistQuantity } from "../../redux/apiCalls";

import DeleteIcon from "../assets/icons/delete.svg";

export default function Wishlist() {
  const wishlistProducts = useSelector(
    (state: RootState) => state.wishlist.items
  );
  const cartProducts = useSelector((state: RootState) => state.cart.products);
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();

  const handleAddToCart = (product: Product, quantity: number) => {
    dispatch(addToCart({ product, quantity: quantity }));
    const existingItem = cartProducts?.find(
      (item) => item?.product?._id === product._id
    );

    if (existingItem) {
      toast.error("Produkti ekzistonë në shportë!");
    } else {
      toast.success("Produkti u shtua në shportë.");
    }
  };

  useEffect(() => {
    fetchWishlistQuantity(dispatch);
  }, [dispatch]);

  return (
    <section className="wishlist container">
      {wishlistProducts?.length > 0 ? (
        <table className="tableWishlist">
          <tr className="firstRow">
            <th className="fs500 semiBold ffPoppins clrDarkGray">Produkti</th>
            <th className="fs500 semiBold ffPoppins clrDarkGray">Qmimi</th>
            <th className="fs500 semiBold ffPoppins clrDarkGray">Statusi</th>
            <th className="fs500 semiBold ffPoppins clrDarkGray">Cart</th>
            <th className="fs500 semiBold ffPoppins clrDarkGray">Remove</th>
          </tr>
          {wishlistProducts?.map((product) => (
            <tr key={product?._id}>
              <td
                className="ffPoppins fs400 clrBlack medium"
                data-title="Product"
              >
                <Link className="productLink" to={`/product/${product?._id}`}>
                  <img
                    className="productImage"
                    src={`${apiUrl}/images/${product?.images[0]}`}
                    width={75}
                    height={75}
                    alt="Product"
                  />
                  {product?.title.substring(0, 35)}...
                </Link>
              </td>
              <td
                className="ffPoppins fs400 clrBlack medium"
                data-title="Price"
              >
                {user?.role === "shumice"
                  ? product?.priceShumice
                  : product?.pricePakice}
                €
              </td>
              <td
                className="ffPoppins fs400 clrBlack medium"
                data-title="Stock Status"
              >
                {Number(product?.sasia) >= 1 ? "In Stock" : "Out of Stock"}
              </td>
              {product?.sasia > 0 ? (
                <td className="ffPoppins fs400 clrBlack medium">
                  <button
                    type="button"
                    className="cartBtn fs400 clrWhite pointer transition"
                    onClick={() => handleAddToCart(product, 1)}
                  >
                    Add to Cart
                  </button>
                </td>
              ) : (
                <td className="ffPoppins fs400 clrBlack medium">
                  <button
                    type="button"
                    className="cartBtn fs400 clrWhite pointer transition disableBtn"
                    onClick={() => handleAddToCart(product, 1)}
                    disabled={true}
                  >
                    Add to Cart
                  </button>
                </td>
              )}
              <td
                className="ffPoppins fs400 clrBlack medium"
                data-title="Remove"
              >
                <img
                  className="pointer"
                  src={DeleteIcon}
                  alt="Delete Icon"
                  width={18}
                  height={18}
                  onClick={() => dispatch(toggleWishlist(product))}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : (
        <div className="emptyWishlist">
          <h3 className="fs800 semiBold">Your wishlist is empty.</h3>
        </div>
      )}
    </section>
  );
}
