import Category from "./Category";

import GraphicCard from "../../assets/images/subcategories/graphicCard.jpeg";
import Procesor from "../../assets/images/subcategories/procesor.jpeg";
import MotherBoard from "../../assets/images/subcategories/motherBoard.jpg";
import RamMemory from "../../assets/images/subcategories/ramMemory.jpeg";
import DiskSSD from "../../assets/images/subcategories/diskSSD.jpeg";
import Cooler from "../../assets/images/subcategories/cooler.jpeg";
import PowerSupply from "../../assets/images/subcategories/powerSupply.webp";
import CD from "../../assets/images/subcategories/opticDisc.png";

export default function PcComponents() {
  return (
    <div className="navSubCategories navSubCategoriesPcComponents flex">
      <Category img={GraphicCard} to="/Kartela Grafike">
        Kartela Grafike
      </Category>
      <Category img={Procesor} to="/Procesor">
        Procesor
      </Category>
      <Category img={MotherBoard} to="/Pllakë Amë">
        Pllakë Amë
      </Category>
      <Category img={RamMemory} to="/Memorie">
        Memorie
      </Category>
      <Category img={DiskSSD} to="/SSD">
        Disk/SSD
      </Category>
      <Category img={Cooler} to="/Ftohës">
        Ftohës
      </Category>
      <Category img={PowerSupply} to="/Furnizues i rrymës">
        Furnizues i Rrymës
      </Category>
      <Category img={CD} to="/Disqe Optike">
        Disqe Optike
      </Category>
    </div>
  );
}
