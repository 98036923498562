import Header from "../components/Header";
// import Categories from "../components/Categories";
import SignUp from "../components/SignUp";
import Location from "../components/Location";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

export default function Signup() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <SignUp />
      <Location />
      <Footer />
    </>
  );
}
