import Hikvision from "../assets/images/partners/hikvision.png";
import AsRock from "../assets/images/partners/asRock.png";
import LcPower from "../assets/images/partners/lcPower.webp";
import Manli from "../assets/images/partners/manli.webp";
import IntelPartner from "../assets/images/partners/intelPartner.png";
import SectionTitle from "./SectionTitle";

export default function Partners() {
  return (
    <section className="partnersContainer container flex">
      <SectionTitle>Partnerët tonë</SectionTitle>
      <div className="partners flex">
        <img className="partnerImg" src={Hikvision} alt="Hikvision Logo" />
        <img className="partnerImg" src={AsRock} alt="AsRock Logo" />
        <img className="partnerImg" src={LcPower} alt="LcPower Logo" />
        <img className="partnerImg" src={Manli} alt="Manli Logo" />
        <img
          className="partnerImg"
          src={IntelPartner}
          alt="Intel Partner Logo"
        />
      </div>
    </section>
  );
}
