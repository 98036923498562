import React, { useState, useEffect } from "react";

export default function Banner({ images }: any) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div className="slider-container">
      <div
        className="slider"
        style={{
          transform: `translateX(-${currentImageIndex * 100}%)`,
          transition: `transform ${500}ms ease`,
        }}
      >
        {images.map((image: any) => (
          <img
            key={image?._id}
            src={image?.image}
            alt={`Slide ${image?._id}`}
            className="slide"
          />
        ))}
      </div>
    </div>
  );
}
