import Category from "./Category";

import Router from "../../assets/images/subcategories/router.jpeg";
import Keyboard from "../../assets/images/subcategories/keyboard.jpeg";
import Mouse from "../../assets/images/subcategories/mouse.webp";
import Headphones from "../../assets/images/subcategories/headphones.jpeg";
import USB from "../../assets/images/subcategories/usb.jpeg";
import Charger from "../../assets/images/subcategories/charger.png";

export default function Accessories() {
  return (
    <div className="navSubCategories navSubCategoriesAccessories flex">
      <Category img={Router} to="/Paisje për rrjet">
        Paisje për Rrjet
      </Category>
      <Category img={Keyboard} to="/Tastierë">
        Tastierë
      </Category>
      <Category img={Mouse} to="/Maus">
        Maus
      </Category>
      <Category img={Headphones} to="/Kufje">
        Kufje
      </Category>
      <Category img={USB} to="/USB">
        USB/Micro SD
      </Category>
      <Category img={Charger} to="/Adapter">
        Adapter
      </Category>
    </div>
  );
}
