import Category from "./Category";
import Tablet from "../../assets/images/subcategories/tablet.jpeg";
import Printer from "../../assets/images/subcategories/printer.jpeg";
import PcOffice from "../../assets/images/subcategories/officeBuild.jpeg";
import Monitor from "../../assets/images/subcategories/monitorOffice.jpeg";
import AIO from "../../assets/images/subcategories/aioDesktop.png";
import Charger from "../../assets/images/subcategories/charger.png";
import AccessoriesOffice from "../../assets/images/subcategories/accessoriesOffice.jpeg";
import OfficeBuild from "../../assets/images/subcategories/betaOfficeBuildPc.webp";

export default function Office() {
  return (
    <div className="navSubCategories navSubCategoriesOffice flex">
      <Category img={PcOffice} to="/BetaTech Office Build">
        BetaTech Office Build
      </Category>
      <Category img={OfficeBuild} to="/PC Office Brand">
        PC Office Brand
      </Category>
      <Category img={Monitor} to="/Monitor Office">
        Monitor Office
      </Category>
      <Category img={Printer} to="/Printer & Toner">
        Printer & Toner
      </Category>
      <Category img={AIO} to="/AIO Desktop PC">
        AIO Desktop PC
      </Category>
      <Category img={AccessoriesOffice} to="/Aksesorë për zyrë">
        Aksesorë per Zyrë
      </Category>
      <Category img={Charger} to="/Adapter">
        Adapter
      </Category>
      <Category img={Tablet} to="/Tablet">
        Tabletë
      </Category>
    </div>
  );
}
