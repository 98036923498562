// import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Location from "../components/Location";
import FilteredProductsComponent from "../components/FilteredProducts";
import Navigation from "../components/Navigation";

export default function FilteredProducts() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <FilteredProductsComponent />
      <Location />
      <Footer />
    </>
  );
}
