import { Link } from "react-router-dom";

export default function Category({ children, to, img }) {
  return (
    <li className="ffPoppins fs600">
      <Link className="subCategory flex" to={to}>
        <img src={img} alt={children} height={100} />
        <h3 className="fs500 ffPoppins transition">{children}</h3>
      </Link>
    </li>
  );
}
