import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/AdminPanel/Sidebar/Sidebar";
import SearchBar from "./components/AdminPanel/TopBar/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "./redux/store";
import { isTokenExpired } from "./helpers/isTokenExpired";
import { userSlice } from "./redux/userRedux";

import "./components/AdminPanel/style.css";
import axios from "axios";
import { apiUrl } from "./constants/backendUrl";

//kete komponente e kemi kriju vetem per admin panel.
const AdminLayout = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!user) {
    navigate("/login");
  }

  if (user && !user?.isAdmin) {
    navigate("/notfound");
  }

  if (user && isTokenExpired(user?.token)) {
    // Token is expired
    dispatch(userSlice?.actions?.logout());
  }

  //kjo metode sepse useri mundet me ndryshu isAdmin: true me bo ne localstorage manualisht e pastaj funksionon me hi ne admin panel, e prandaj e bejme kete e ne adminLayout ne react ne useEffect bejme request ktu me kshyr a eshte tokeni i adminit valid apo jo.

  useEffect(() => {
    axios
      .get(`${apiUrl}/admin/user`, {
        headers: { Authorization: "Bearer " + user?.token },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        navigate("/notfound");
      });
  }, [user, navigate]);

  return (
    <>
      <SearchBar />
      <div className="main-container">
        <Outlet />
      </div>

      <Sidebar />
    </>
  );
};

export default AdminLayout;
