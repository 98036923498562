import Product from "../../client/components/ProductCard";
import ProductContainer from "../../client/components/ProductsContainer";
import SkeletonPost from "../Skeletons/SkeletonPost";

const RelatedProducts = ({ relatedProducts, loading }: any) => {
  // useEffect(() => {
  //     getProducts(dispatch);
  // }, [sortOrder])

  // if (loadingPage || isLoading || error) return <Loader />

  if (loading) {
    return (
      <div className="container">
        <div className="row">
          {Array.from({ length: 8 }).map((_, i) => (
            <div className="col-lg-3" key={i}>
              <SkeletonPost />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {relatedProducts?.length > 0 && (
        <div className="container p-0">
          <div>
            <h3
              className="ffPoppins fs800 medium clrPrimary"
              style={{ marginBlock: "75px 25px" }}
            >
              Produkte të ngjashme:
            </h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-content">
                <div className="row shop_container productSingleContainer">
                  {relatedProducts?.map((product: any) => (
                    <div key={product?._id} style={{ width: "auto" }}>
                      <Product data={product} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RelatedProducts;
