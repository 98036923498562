export default function OrderReview({
  handleSubmit,
  user,
  cartProducts,
  onSendEmail,
}: any) {
  let totalPrice = cartProducts?.reduce((a: any, product: any) => {
    if (product?.sasia === 0) {
      return a;
    } else {
      return (
        a +
        (user?.role === "shumice"
          ? product?.product?.priceShumice
          : product?.product?.pricePakice) *
          product.quantity
      );
    }
  }, 0);

  return (
    <div className="yourOrder flex">
      <h2 className="fs800 bold ffPoppins clrBlack">Porositë tuaja</h2>
      <table className="yourOrderTable">
        <tr>
          <th className="fs600 semiBold ffPoppins clrBlack">Produkti</th>
          <th className="fs600 semiBold ffPoppins clrBlack">Qmimi</th>
        </tr>
        {cartProducts?.map((product: any) => {
          // if (product?.sasia === 0) {
          //     return null; // Skip the product if sasia is 0
          // }

          return (
            <tr key={product?.product._id}>
              {product?.sasia !== 0 && (
                <>
                  <td className="fs500 medium ffPoppins clrDarkGray">
                    {product?.product?.title}
                  </td>
                  <td className="fs400 medium ffPoppins clrDarkGray flex">
                    {product?.quantity}x
                    <span style={{ marginLeft: 5 }}>
                      {product?.sasia === 0 && (
                        <span className="badge bg-red"> Out of Stock</span>
                      )}
                    </span>
                    {(user?.role === "shumice"
                      ? product?.product?.priceShumice
                      : product?.product?.pricePakice
                    )?.toFixed(2)}
                    €
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </table>
      <div className="totalContainer flex ">
        <h3 className="fs700 semiBold ffPoppins clrBlack">Totali</h3>
        <h4 className="fs700 semiBold ffPoppins clrBlack">
          {cartProducts
            .reduce((a: any, product: any) => {
              if (product?.sasia === 0) {
                return a; // Skip the product if sasia is 0
              }
              const price =
                user?.role === "shumice"
                  ? product?.product?.priceShumice
                  : product?.product?.pricePakice;
              return a + price * product.quantity;
            }, 0)
            .toFixed(2)}
          €
        </h4>
      </div>
      {cartProducts?.length > 0 && totalPrice !== 0 && (
        <button
          type="button"
          className="blueBtn fs600 semiBold ffPoppins clrWhite transition pointer"
          onClick={(e) => {
            onSendEmail(e);
            handleSubmit();
          }}
        >
          Porositni
        </button>
      )}
    </div>
  );
}
