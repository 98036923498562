import Header from "../components/Header";
// import Categories from "../components/Categories";
import LoginComponent from "../components/SignIn";
import Location from "../components/Location";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

export default function Login() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <LoginComponent />
      <Location />
      <Footer />
    </>
  );
}
