import Category from "./Category";

import MonitorGaming from "../../assets/images/subcategories/monitorGaming.jpeg";
import MonitorOffice from "../../assets/images/subcategories/monitorOffice.jpeg";
import Accessories from "../../assets/images/subcategories/accessories.jpeg";
import Cable from "../../assets/images/subcategories/cable.jpeg";

export default function Monitor() {
  return (
    <div className="navSubCategories navSubCategoriesMonitor flex">
      <Category img={MonitorGaming} to="/Monitor Gaming">
        Monitor Gaming
      </Category>
      <Category img={MonitorOffice} to="/Monitor Office">
        Monitor Office
      </Category>
      <Category img={Accessories} to="/Aksesorë">
        Aksesorë
      </Category>
      <Category img={Cable} to="/Kabllo">
        Kabllo
      </Category>
    </div>
  );
}
