import { Link } from "react-router-dom";
import Gaming from "./categories/Gaming";
import PcComponents from "./categories/PcComponets";
import Laptop from "./categories/Laptop";
import Monitor from "./categories/Monitor";
import Accessories from "./categories/Accessories";
import Office from "./categories/Office";

export default function Navigation() {
  return (
    <>
      <nav className="navContainer container">
        <ul className="nav flex">
          <li className="ffPoppins navLinkGaming fs600">
            <Link className="navLink transition uppercase" to="/gaming">
              Gaming
            </Link>
            <Gaming />
          </li>
          <li className="ffPoppins navLinkPcComponents fs600">
            <Link className="navLink transition uppercase" to="/pc">
              Pjesë për PC
            </Link>
            <PcComponents />
          </li>
          <li className="ffPoppins navLinkLaptop fs600">
            <Link className="navLink transition uppercase" to="/laptop">
              Laptop
            </Link>
            <Laptop />
          </li>
          <li className="ffPoppins navLinkMonitor fs600">
            <Link className="navLink transition uppercase" to="/monitor">
              Monitor
            </Link>
            <Monitor />
          </li>
          <li className="ffPoppins navLinkAccessories fs600">
            <Link className="navLink transition uppercase" to="/aksesor">
              Aksesorë
            </Link>
            <Accessories />
          </li>
          <li className="ffPoppins navLinkOffice fs600">
            <Link className="navLink transition uppercase" to="/office">
              Office
            </Link>
            <Office />
          </li>
          <li className="ffPoppins fs600 navLinkSale">
            <Link
              className="navLink transition uppercase"
              style={{ color: "red" }}
              to="/zbritje"
            >
              Zbritje
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
