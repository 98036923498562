import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { login } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { handleOnChange } from "../../helpers/handleChange";

import { RootState } from "../../redux/store";
import { UserData } from "../../types/types";
import { validateForm } from "../../helpers/validateForm";

import { SpinnerLoader } from "../../helpers/SpinnerLoader";

export default function SignIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({} as UserData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, user, loading } = useSelector(
    (state: RootState) => state.user
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validateForm(formData);
    setErrors(errors);
    // validation here before continue
    if (Object.keys(errors).length === 0) {
      login(formData?.email, formData?.password, dispatch);
    }
  };

  useEffect(() => {
    if (user && user.isAdmin) {
      navigate("/app/admin");
    } else if (user && !user.isAdmin) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [user, dispatch, navigate]);

  return (
    <section className="registerCard flex">
      <div className="registerOptions flex">
        <Link
          to="/login"
          className="registerOption clrSecondary fs600 semiBold uppercase pointer activeOption"
        >
          Log In
        </Link>
        <Link
          to="/sign-up"
          className="registerOption clrGray fs600 semiBold uppercase pointer"
        >
          Sign Up
        </Link>
      </div>
      <div className="registerForm flex">
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="email"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Email *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData!.email}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.email && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.email}</p>
            </div>
          )}
          <label
            htmlFor="password"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Fjalëkalimi *
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.password}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.password && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.password}</p>
            </div>
          )}
          <button
            type="submit"
            className="submit uppercase transition fs500 semiBold ffPoppins clrWhite"
          >
            {!loading ? "Log In" : <SpinnerLoader />}
          </button>
        </form>
        <p className="noPass fs400 ffPoppins clrDarkGray">
          Dont have an Account?{" "}
          <Link className="noPassLink" to="/sign-up">
            Sign Up
          </Link>
        </p>
      </div>
    </section>
  );
}
