import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/apiCalls";
import { handleOnChange } from "../../helpers/handleChange";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UserData } from "../../types/types";
import { validateForm } from "../../helpers/validateForm";
import { SpinnerLoader } from "../../helpers/SpinnerLoader";
import { userSlice } from "../../redux/userRedux";

import NumberInput from "../../helpers/NumberInput";

export default function SignUp() {
  const [formData, setFormData] = useState<UserData>({
    name: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    password: "",
  });
  const [confirmPassowrd, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errors, setErrors] = useState({} as UserData);

  const { success, loading } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validateForm(formData);
    setErrors(errors);
    // validation here before continue

    if (Object.keys(errors).length === 0) {
      if (confirmPassowrd !== formData?.password) {
        return setPasswordError("Passwords are not the same!");
      } else {
        setPasswordError("");
        register(formData, dispatch);
      }
    }
  };

  useEffect(() => {
    if (success) {
      setFormData({
        name: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        password: "",
      });
      setConfirmPassword("");
      navigate("/login");
    }

    return () => {
      dispatch(userSlice.actions.clearState());
    };
  }, [success, dispatch, navigate]);

  return (
    <section className="registerCard flex">
      <div className="registerOptions flex">
        <Link
          to="/login"
          className="registerOption clrGray fs600 semiBold uppercase pointer"
        >
          Log In
        </Link>
        <Link
          to="sign-up"
          className="registerOption clrSecondary fs600 semiBold uppercase pointer activeOption"
        >
          Sign Up
        </Link>
      </div>
      <div className="registerForm flex">
        {/* <h4
          style={{ margin: "auto" }}
          className="fs600 clrDarkGray medium ffPoppins uppercase"
        >
          Companies only
        </h4> */}
        {passwordError && (
          <div
            style={{ margin: "auto" }}
            className="fs600 clrDarkGray medium ffPoppins uppercase"
          >
            <p>{passwordError}</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="name"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Emri *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.name}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.name && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.name}</p>
            </div>
          )}
          <label
            htmlFor="surname"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Mbiemri *
          </label>
          <input
            type="text"
            id="surname"
            name="lastName"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.lastName}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.lastName && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.lastName}</p>
            </div>
          )}
          <label
            htmlFor="email"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Email *
          </label>
          <input
            type="email "
            id="email"
            name="email"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.email}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.email && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.email}</p>
            </div>
          )}
          <label
            htmlFor="company"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Kompania *
          </label>
          <input
            type="text"
            id="company"
            name="company"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.company}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.company && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.company}</p>
            </div>
          )}
          <label
            htmlFor="phoneNumber"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Numri i telefonit *
          </label>
          <NumberInput
            placeholder=""
            name="phone"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.phone}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.phone && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.phone}</p>
            </div>
          )}
          <label
            htmlFor="password"
            className="inputLabel fs500 medium clrDarkGray ffPoppins"
          >
            Fjalëkalimi *
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={formData?.password}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          {errors?.password && (
            <div className="heading_error">
              <p className="text-left mb-0">{errors?.password}</p>
            </div>
          )}
          <label
            htmlFor="retypePassword"
            className="inputLabel fs500 medium clrDarkGray ffPoppins "
          >
            Rishkruani fjalëkalimin *
          </label>
          <input
            type="password"
            id="retypePassword"
            name="retypePassword"
            className="input signUpInput fs400 clrDarkGray ffPoppins"
            value={confirmPassowrd}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            type="submit"
            className="submit uppercase transition fs500 semiBold ffPoppins clrWhite"
            disabled={loading}
          >
            {!loading ? "Sign Up" : <SpinnerLoader />}
          </button>
        </form>
      </div>
    </section>
  );
}
