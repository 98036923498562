import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
  return (
    <>
      <div>
        <div className="section notfound text-center">
          <div className="heading_s1">
            <h2 className="title ffPoppins fs800 bold">404</h2>
          </div>
          <p>
            <b className="fs600 ffPoppins clrDarkGray">
              oops! The page you requested was not found
            </b>
          </p>
          <p className="fs600 ffPoppins clrDarkGray">
            The page you are looking was removed or might never existed.
          </p>

          <Link to="/" className="btnBlue404 fs600 medium clrWhite">
            Go back home!
          </Link>
        </div>
      </div>
    </>
  );
};

export default Notfound;
