import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getCategories, getProducts } from "../../redux/apiCalls";

// import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Location from "../components/Location";
import ProductContainer from "../components/ProductsContainer";
import Navigation from "../components/Navigation";

export default function Shop() {
  const disptach = useDispatch();
  const { sortOrder } = useSelector((state: RootState) => state.product);

  useEffect(() => {
    getCategories(disptach);
    getProducts(disptach);
  }, [sortOrder, disptach]);

  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <ProductContainer title="All products" more={false} filter={true} />
      <Location />
      <Footer />
    </>
  );
}
