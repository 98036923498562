// import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Location from "../components/Location";
import CheckoutComponent from "../components/Checkout";
import Navigation from "../components/Navigation";

export default function Checkout() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <CheckoutComponent />
      <Location />
      <Footer />
    </>
  );
}
