import { Link } from "react-router-dom";
import { apiUrl } from "../../constants/backendUrl";
import { useDispatch, useSelector } from "react-redux";
import { toggleWishlist } from "../../redux/wishlistRedux";
import { addToCart } from "../../redux/shoppingCartRedux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

export default function ProductCard({ data }: any) {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state: RootState) => state.cart.products);
  const user = useSelector((state: RootState) => state.user.user);

  const handleClick = () => {
    dispatch(toggleWishlist(data));
  };

  const handleAddToCartClick = () => {
    const existingItem: any = cartProducts.find(
      (item) => item?.product?._id === data._id
    );
    dispatch(addToCart({ product: data, quantity: 1 }));

    if (existingItem) {
      toast.error("Produkti ekziston në shportë!");
    } else if (existingItem?.product?.sasia < 1) {
      toast.error("Nuk ka sasi te mjaftueshme ne stock.");
    } else {
      toast.success("Produkti u shtua në shportë.");
    }
  };

  const calculateDiscountedPrice = () => {
    if (data?.zbritja) {
      if (user?.role === "shumice") {
        return (data?.priceShumice - data?.zbritja).toFixed(2);
      } else {
        return (data?.pricePakice - data?.zbritja).toFixed(2);
      }
    }
    return null;
  };

  const discountedPrice = calculateDiscountedPrice();

  return (
    <div className="productCard flex pointer transition">
      <Link to={`/product/${data?._id}`} className="productCardImageContainer">
        <img
          className="productCardImage"
          src={`${apiUrl}/images/${data?.images[0]}`}
          alt="Product"
        />
        {discountedPrice && (
          <h4 className="productCardSale clrWhite ffPoppins fs500">
            Sale{" "}
            {user?.role === "shumice"
              ? (
                  ((data?.priceShumice?.toFixed(2) - Number(discountedPrice)) /
                    data?.priceShumice?.toFixed(2)) *
                  100
                ).toFixed(2)
              : (
                  ((data?.pricePakice?.toFixed(2) - Number(discountedPrice)) /
                    data?.pricePakice?.toFixed(2)) *
                  100
                ).toFixed(0)}
            %
          </h4>
        )}
      </Link>
      <div className="productCardAbout flex">
        <div>
          <h2 className="productCardName clrDarkGray fs600 ffPoppins medium">
            {data?.title.substring(0, 20)}...
          </h2>
          <p className="productCardDescription clrGray fs300 medium">
            {data?.description.substring(0, 50)}...
          </p>
          <div className="productCardPriceContainer flex">
            <p className="fs500 clrPrimary medium">
              {discountedPrice
                ? discountedPrice
                : user?.role === "shumice"
                ? data?.priceShumice?.toFixed(2)
                : data?.pricePakice?.toFixed(2)}
              €
            </p>
            <p className="fs500 clrPrimaryOpacity medium">
              {discountedPrice &&
                (user?.role === "shumice"
                  ? data?.priceShumice?.toFixed(2)
                  : data?.pricePakice?.toFixed(2))}
              {discountedPrice && "€"}
            </p>
          </div>
        </div>
        <div className="productCardLinks flex">
          <button
            disabled={data?.sasia > 0}
            className="fs500 clrPrimary medium pointer"
            onClick={handleAddToCartClick}
          >
            Add to Cart
          </button>
          <button
            className="fs500 clrPrimary medium pointer"
            onClick={handleClick}
          >
            Add to Wishlist
          </button>
        </div>
      </div>
    </div>
  );
}
