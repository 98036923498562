// Dependencies
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Link, useNavigate } from "react-router-dom";
// Assets
import Logo from "../../assets/images/logoTwo.png";
import ShoppingCartIcon from "../../assets/icons/shoppingCart.svg";
import FavouritesIcon from "../../assets/icons/heart.svg";
import AccountIcon from "../../assets/icons/user.svg";
import SearchIcon from "../../assets/icons/search.svg";
import SettingsIcon from "../../assets/icons/settings.svg";

export default function HeaderBottom() {
  const { user } = useSelector((state: RootState) => state.user);
  // In case we want to add how many products we have on cart as a notification over the cart icon

  const [search, setSearch] = useState("");
  const navigateTo = useNavigate();

  const handleSearchSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigateTo(`/${search}`);
  };

  return (
    <section className="headerBottomWrapper">
      <div className="container">
        <div className="headerBottom flex">
          <Link to="/">
            <img src={Logo} alt="BetaTech Logo" width={200} />
          </Link>
          <form className="searchContainer flex" onSubmit={handleSearchSubmit}>
            <input
              className="search clrDarkGray"
              type="text"
              placeholder="Kërko..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="searchButton" type="submit">
              <img src={SearchIcon} alt="Search Icon" />
            </button>
          </form>
          <div className="headerButtonContainer flex">
            <Link to="/cart">
              <img
                src={ShoppingCartIcon}
                alt="Shopping Cart Icon"
                width={30}
                height={30}
              />
            </Link>
            <Link to="/wishlist">
              <img
                src={FavouritesIcon}
                alt="Favourites Icon"
                width={30}
                height={30}
              />
            </Link>
            {(user || !user || !user.isAdmin) && (
              <Link to="/account">
                <img
                  src={AccountIcon}
                  alt="Account Icon"
                  width={30}
                  height={30}
                />
              </Link>
            )}
            {user?.isAdmin && (
              <Link to="/app/admin">
                <img
                  src={SettingsIcon}
                  alt="Account Icon"
                  width={30}
                  height={30}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
