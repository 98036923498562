import Header from "../components/Header";
// import Categories from "../components/Categories";
import CartContainer from "../components/CartContainer";
import Location from "../components/Location";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

export default function Cart() {
  return (
    <>
      <Header />
      {/* <Categories /> */}
      <Navigation />
      <CartContainer />
      <Location />
      <Footer />
    </>
  );
}
