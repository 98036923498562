import { useState, useRef, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { apiUrl } from "../../constants/backendUrl";
import { User } from "../../types/types";
import { handleOnChange } from "../../helpers/handleChange";
import { resetCart } from "../../redux/shoppingCartRedux";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import OrderReview from "./checkout/OrderReview";

interface IData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zip: string;
  phone: string;
  email: string;
  user?: User;
  orderItems?: Array<any>;
}

export default function Checkout() {
  const user = useSelector((state: RootState) => state.user.user);
  const cartProducts = useSelector((state: RootState) => state.cart.products);
  const [formData, setFormData] = useState<IData>({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zip: "",
    phone: "",
    email: "",
    user: user,
    orderItems: cartProducts,
  });
  const [errors, setErrors] = useState({} as IData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    console.log(formRef.current);

    if (formRef.current !== null) {
      emailjs.sendForm(
        "service_gbvgj59", // SERVICE ID
        "template_f2g9ghr", // TEMPLATE ID
        formRef.current,
        "DqPdRDf_HgHLeQiCT" // PUBLIC KEY
      );

      formRef.current.reset();
    }
  };

  const handleSubmit = () => {
    const errors = {} as IData;

    if (formData?.firstName === "") {
      errors.firstName = "First Name is required";
    }

    if (formData?.lastName === "") {
      errors.lastName = "Last Name is required";
    }

    if (formData?.address === "") {
      errors.address = "Address is required";
    }

    if (formData?.city === "") {
      errors.city = "City is required";
    }

    if (formData?.zip === "") {
      errors.zip = "Zip is required";
    }

    if (formData?.phone === "") {
      errors.phone = "Phone is required";
    }

    if (!formData?.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData?.email)) {
      errors.email = "Email is invalid";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0 && cartProducts?.length > 0) {
      axios
        .post(`${apiUrl}/orders`, formData, {
          headers: { Authorization: "Bearer " + user?.token },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(resetCart());
            setFormData({
              firstName: "",
              lastName: "",
              address: "",
              city: "",
              zip: "",
              phone: "",
              email: "",
            });
            navigate("/order-completed");
          }
        })
        .catch((err) => {
          if (err) {
            toast.error("Nuk mund të bleni produkt që është out of stock!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };
  let productsString = "";
  cartProducts.forEach(
    (product, i) =>
      (productsString += `${i + 1}. ${product.product.title} ( Sasia: ${
        product.quantity
      } - Çmimi: ${product.product.pricePakice} € ) `)
  );

  console.log(productsString);

  return (
    <section className="checkout flex container">
      <form className="checkoutForm flex" ref={formRef}>
        <div className="checkoutFormInputContainer flex">
          <label
            className="fs600 clrBlack medium ffPoppins"
            htmlFor="firstName"
          >
            Emri*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="text"
            name="firstName"
            value={formData?.firstName}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.firstName}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="lastName">
            Mbiemri*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="text"
            name="lastName"
            value={formData?.lastName}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.lastName}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="address">
            Adresa*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="text"
            name="address"
            value={formData?.address}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.address}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="city">
            Qyteti*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="text"
            name="city"
            value={formData?.city}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.city}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="zip">
            Numri postar*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="text"
            name="zip"
            value={formData?.zip}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.zip}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="phone">
            Numri i telefonit*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            name="phone"
            type="number"
            value={formData?.phone}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.phone}</span>
        </div>
        <div className="checkoutFormInputContainer flex">
          <label className="fs600 clrBlack medium ffPoppins" htmlFor="email">
            Email*
          </label>
          <input
            className="checkoutFormInputs fs500 ffPoppins clrDarkGray"
            type="email"
            name="email"
            value={formData?.email}
            onChange={(e) =>
              handleOnChange(e, formData, setFormData, errors, setErrors)
            }
          />
          <span className="span-error">{errors?.email}</span>
        </div>
        <input
          type="text"
          value={productsString}
          style={{ display: "none " }}
          name="products"
        />
      </form>
      <OrderReview
        handleSubmit={handleSubmit}
        user={user}
        cartProducts={cartProducts}
        onSendEmail={sendEmail}
      />
    </section>
  );
}
