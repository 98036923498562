import { Link } from "react-router-dom";
import Done from "../assets/icons/done.svg";

export default function OrderCompletedComponent() {
  return (
    <section className="done container flex">
      <img src={Done} alt="Done Icon" width={100} />
      <div>
        <h2 className="fs800 clrBlack ffPoppins bold">
          Porosia juaj është realizuar!
        </h2>
        <h4 className="fs600 clrGray ffPoppins semiBold">
          Faleminderit për porosinë tuaj!
        </h4>
      </div>
      <Link
        className="doneButton transition pointer fs500 uppercase clrWhite medium"
        to="/"
      >
        Vazhdoni të blini
      </Link>
    </section>
  );
}
