import HeaderBottom from "./header/HeaderBottom";
import HeaderTop from "./header/HeaderTop";

export default function Header() {
  return (
    <header className="header">
      <HeaderTop />
      <HeaderBottom />
    </header>
  );
}
