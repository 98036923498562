import React, { useEffect, useState } from "react";
import { getProducts, deleteProduct } from "../../../redux/apiCalls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import DeleteIcon from "../../../images/delete-icon.svg";
import EditIcon from "../../../images/edit.svg";
import DeleteConfirmation from "../../Modals/DeleteConfirmation";
import Loader from "../../../Loader";
import { apiUrl } from "../../../constants/backendUrl";
import DangerAlert from "../../../helpers/DangerAlert";
import { Link } from "react-router-dom";

const ManageProducts = () => {
  const [show, setShow] = useState(false);
  const [productId, setProductId] = useState("");
  const { products, isLoading, isDeleted, error, errorMsg } = useSelector(
    (state: RootState) => state.product
  );
  const { user } = useSelector((state: RootState) => state.user);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (id: any) => {
    setShow(true);
    setProductId(id);
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filteredProducts = products.filter((product) => {
    const searchValue = searchQuery.toLowerCase();
    const categoryMatch = product.category?.toLowerCase().includes(searchValue);
    const subCategoryMatch = product.subCategory
      ?.toLowerCase()
      .includes(searchValue);
    const priceShumiceMatch = product.priceShumice
      ?.toString()
      .includes(searchValue);
    const pricePakiceMatch = product.pricePakice
      ?.toString()
      .includes(searchValue);
    const sasiaMatch = product.sasia?.toString().includes(searchValue);
    const descriptionMatch = product.description
      ?.toLowerCase()
      .includes(searchValue);
    const zbritjaMatch = product.zbritja?.toString().includes(searchValue);

    return (
      categoryMatch ||
      subCategoryMatch ||
      priceShumiceMatch ||
      pricePakiceMatch ||
      sasiaMatch ||
      descriptionMatch ||
      zbritjaMatch
    );
  });

  useEffect(() => {
    getProducts(dispatch);
  }, [isDeleted, user, dispatch]);

  if (error) return <DangerAlert msg={errorMsg} />;

  return (
    <div>
      {isLoading && <Loader />}
      <DeleteConfirmation
        show={show}
        setShow={setShow}
        deleteFunc={deleteProduct}
        id={productId}
        dispatch={dispatch}
        user={user}
      />
      <div className="mb-5">
        <h5>Search product</h5>
        <input
          type="search"
          className="form-control"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Kërko produktin"
        />
      </div>

      <div className="product-table-wrapper custom-scrollbar-vertical">
        <table className="general-table">
          <thead>
            <tr className="table100-head">
              <th>Category</th>
              <th>Description</th>
              <th>Sub Category</th>
              <th>Cmimi Me Shumice</th>
              <th>Cmimi Me Pakice</th>
              <th>InStock</th>
              <th>Images</th>
              <th>Sasia</th>
              <th>zbritja</th>
              <th>Featured Product</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts?.map((product) => (
              <tr key={product?._id}>
                <td data-label="Category">{product?.category}</td>
                <td data-label="Description">{product?.description}</td>
                <td data-label="SubCategory">{product?.subCategory}</td>
                <td data-label="Price Shumice">{product?.priceShumice}€</td>
                <td data-label="Price Pakice">{product?.pricePakice}€</td>
                <td data-label="InStock">{product?.sasia > 0 ? "Po" : "Jo"}</td>
                <td data-label="Images">
                  <div>
                    <img
                      className="img img-responsive img-thumbnail"
                      src={`${apiUrl}/images/${product.images[0]}`}
                      alt=""
                      style={{ width: "160px", height: "auto" }}
                    />
                  </div>
                </td>
                <td>{product?.sasia}</td>
                <td data-label="Zbritja">{product?.zbritja}€</td>
                <td data-label="featured">
                  {product?.isFeatured === true
                    ? "Featured Product"
                    : "Not Featured Product"}
                </td>
                <td data-label="Actions" className="actions">
                  <div className="d-flex gap-3">
                    <Link
                      to={`/app/admin/add-product/${product?._id}`}
                      className="edit-table-btn"
                    >
                      <img
                        src={EditIcon}
                        alt="edit icon"
                        height="12"
                        className="me-1"
                      />
                      Edit
                    </Link>
                    <button
                      type="button"
                      className="remove-table-btn"
                      onClick={() => handleDelete(product?._id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="remove icon"
                        className="me-1"
                      />
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageProducts;
