import FooterBottom from "./footer/FooterBottom";
import FooterTop from "./footer/FooterTop";

export default function Footer() {
  return (
    <footer className="footer flex">
      <FooterTop />
      <FooterBottom />
    </footer>
  );
}
