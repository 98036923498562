export default function FooterBottom() {
  return (
    <section className="footerBottom container">
      <p className="ffPoppins fs400 clrDarkGray">
        &copy; {new Date().getFullYear()} BetaTech. Të gjitha të drejtat të
        rezervuara.
      </p>
    </section>
  );
}
