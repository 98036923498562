import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../constants/backendUrl";
import { useParams } from "react-router-dom";
import ProductCard from "./ProductCard";

export default function FilteredProducts({
  fixedCategory,
  filter = true,
}: any) {
  const [productByCategory, setProductsByCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { category } = useParams();

  const [productsToDisplay, setProductsToDisplay] = useState("default");

  useEffect(() => {
    if (category || fixedCategory) {
      setLoading(true);
      axios
        .get(`${apiUrl}/products/by-category/${fixedCategory || category}`)
        .then((res) => {
          setProductsByCategory(res?.data?.products);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fixedCategory, category]);

  const sortedProductsAsc = [...productByCategory].sort(
    (a: any, b: any) => a.pricePakice - b.pricePakice
  );

  const sortedProductsDesc = [...productByCategory].sort(
    (a: any, b: any) => b.pricePakice - a.pricePakice
  );

  return (
    <>
      <div className="filterTitle flex container">
        <h3 className="ffPoppins fs800 semiBold clrBlack">
          {category?.toUpperCase()}
          {fixedCategory}
        </h3>
        {filter && (
          <select
            name="filter"
            id="filter"
            value={productsToDisplay}
            onChange={(e) => {
              setProductsToDisplay(e.target.value);
              console.log(productsToDisplay);
            }}
            className="filterSelect"
          >
            <option value="default">Default</option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
        )}
      </div>
      <div
        className="flex container"
        style={{
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: 25,
        }}
      >
        {category &&
          (productsToDisplay === "default" && productByCategory?.length > 0 ? (
            productByCategory?.map((product: any) => (
              <ProductCard data={product} loading={loading} />
            ))
          ) : productsToDisplay === "asc" && sortedProductsAsc?.length > 0 ? (
            sortedProductsAsc?.map((product: any) => (
              <ProductCard data={product} loading={loading} />
            ))
          ) : productsToDisplay === "desc" && sortedProductsDesc?.length > 0 ? (
            sortedProductsDesc?.map((product: any) => (
              <ProductCard data={product} loading={loading} />
            ))
          ) : (
            <div className="empty_wishlist mt-4 mb-4">
              <h5>Nuk u gjet ndonjë produkt që përputhet me kërkimin tuaj.</h5>
            </div>
          ))}
        {fixedCategory &&
          (productByCategory?.length > 0 ? (
            productByCategory
              ?.slice(0, 8)
              .map((product: any) => (
                <ProductCard data={product} loading={loading} />
              ))
          ) : (
            <div className="empty_wishlist mt-4 mb-4">
              <h5>Nuk u gjet ndonjë produkt që përputhet me kërkimin tuaj.</h5>
            </div>
          ))}
      </div>
    </>
  );
}
