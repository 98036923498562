import SectionTitle from "../SectionTitle";

export default function ContactIntro() {
  return (
    <div className="contactIntro flex">
      <p className="contactSlogan clrBlack fs600 ffPoppins clrBlack">
        Keni ndojne sygjerim apo kërkesë?
      </p>
      <SectionTitle>Na kontaktoni!</SectionTitle>
    </div>
  );
}
