import InstagramLogo from "../../assets/icons/instagram.svg";
import FacebookLogo from "../../assets/icons/facebook.svg";
import PhoneIcon from "../../assets/icons/phone.svg";

export default function HeaderTop() {
  return (
    <section className="headerTopWrapper">
      <div className="container">
        <div className="headerTop flex">
          <div>
            <p className="fs400 clrWhite">
              Për çdo porosi mbi 100€, TRANSPORTI FALAS - KS
            </p>
          </div>
          <div className="flex headerInfo">
            <a href="https://www.instagram.com/betatechshpk" className="flex">
              <img
                src={InstagramLogo}
                alt="Instagram Logo"
                width={16}
                height={16}
              />
            </a>
            <a href="https://www.facebook.com/betatechshpk" className="flex">
              <img
                src={FacebookLogo}
                alt="FaceBook Logo"
                width={16}
                height={16}
              />
            </a>
            <div className="flex">
              <img
                src={PhoneIcon}
                alt="Phone Icon"
                width={16}
                height={16}
                style={{ marginRight: 10 }}
              />
              <p className="fs400 clrWhite">048 220 989 </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
